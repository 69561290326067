import React from 'react';
import './App.css';

const TREE = [
  'Ewainsiha',
  'Osawe',
  'Omagbon',
  'Ediagbonya',
  'Ekenomokhiririaye',
  'Iduonze',
  'Eguavoen',
  'Agbongho',
  'Osayuwu',
  'Ighodaro',
  'Asemota',
  'Igbinoghene', 'Iyamu', 'Anthony Osazemwide', 'Edwin', 'Igbinoghene'
];

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <div>
            <h1>EDEBIRI</h1>
            <ul style={{ listStyle: 'none' }}>
              { TREE.reverse().map((name, i) => Array.isArray(name) ? (
                <ul>
                  { name.map((name, i) => (
                    <li key={i}>{ name } Edebiri</li>
                  ))}
                </ul>
              ) : (
                <li key={i}>{ name } Edebiri</li>
              ))}
            </ul>
          </div>
          <div>
            <img alt='Edebiri 1' src='/img/edebiri-1.jpg' />
            <img alt='Edebiri 2' src='/img/edebiri-2.jpg' />
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
